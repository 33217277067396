import * as Yup from 'yup';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  orStyle: {
    textAlign: 'center'
  }
});

function AddBookInCollectionForm({ open, onAddNew, onCancel }) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Artist</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            bookId: ''
          }}
          validationSchema={Yup.object().shape({
            bookId: Yup.string()
              .trim()
              .max(255)
              .required('Artist ID is required')
          })}
          onSubmit={values => {
            onAddNew(values.bookId);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.bookId && errors.bookId)}
                margin="dense"
                id="bookId"
                label="Artist ID"
                type="text"
                name="bookId"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.bookId && errors.bookId}
                onBlur={handleBlur}
                value={values.bookId}
              />

              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  Add
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

AddBookInCollectionForm.propTypes = {
  open: PropTypes.bool,
  onAddNew: PropTypes.func,
  onCancel: PropTypes.func
};
export default AddBookInCollectionForm;
