// LOCAL
// export const SERVER_HOST_NAME = 'http://localhost:80';

// DEV
//export const SERVER_HOST_NAME = '';

// PROD
// export const SERVER_HOST_NAME = 'https://aow-api.degruyter.com';

export const SERVER_HOST_NAME = process.env.REACT_APP_SERVER_HOST_NAME;
