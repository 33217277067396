import React, { useState, useEffect } from 'react';
import CheckBox from './CheckBox';
import {
  Checkbox as CheckBoxInput,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
  List,
  ListItem
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  hide: {
    display: "none",
  },
  capitalize: {
    textTransform: 'capitalize',
  }
}));

const Multiselect = (props) => {

  const classes = useStyles();
  const [checkedItemList, setCheckedItemList] = useState(props.initialSelected ?? []);

  const handleMultiSelectItem = (event) => {
    const existingState = checkedItemList;
    if (event.target.checked) existingState.push(event.target.name);
    else {
      let idx = existingState.indexOf(event.target.name);
      if (idx != -1) existingState.splice(idx, 1);
    }
    setCheckedItemList(existingState);
    console.log(checkedItemList);
    props.handle(checkedItemList);
  };

  useEffect(() => {
    setCheckedItemList(props.initialSelected);
  });

  if (props?.jsonData == undefined) {
    return <div></div>;
  }

  var multiSelectList = Object.keys(props.jsonData);

  var list = [];

  for (let index = 0; index < multiSelectList.length; index += 5) {
    var tempList = [];
    var j = 0;
    for (j = 0; j < 5 && index + j < multiSelectList.length; j++) {
      tempList.push(
        <li key={multiSelectList[index + j]} className="multi-select-item">
          <CheckBox
            id={multiSelectList[index + j]}
            onChange={handleMultiSelectItem}
            name={multiSelectList[index + j]}
            defaultChecked={checkedItemList.includes(multiSelectList[index + j])}
          ></CheckBox>
          <label htmlFor={multiSelectList[index + j]} className="text-capitalize">{multiSelectList[index + j]}</label>
        </li>
      );
    }
    if (index + j < multiSelectList.length) {
      tempList.push(
        <li className="multi-select-item">
          <a
            href={"#" + props.title.replace(" ", "") + ((index / 5) + 1)}
            class="multi-select-more"
            data-toggle="collapse"
            aria-expanded="false"
            aria-controls={props.title.replace(" ", "") + ((index / 5) + 1)}
          >
            + More
          </a>
        </li>
      );
    }
    list.push(
      <ul id={props.title.replace(" ", "") + (index / 5)} className={((index / 5) == 0 ? "" : "collapse") + " p-0 list-unstyled text-left"}>
        {tempList}
      </ul>
    );
  }
  return (
    <Grid
      container
      direction="column"
    >
      <CheckBoxInput
        className={classes.hide}
        id={props.title}
        defaultChecked={true}
      />

      <Typography variant="h4"
        className="multi-select-container-title"
      >
        {props.title}
      </Typography>
      <div className="multi-select-list fw-500">
        <FormGroup row>
          <List>
            {props.jsonData
              ? Object.keys(props.jsonData).map((sub) => {
                return (
                  <ListItem key={sub} className="multi-select-item">
                    <FormControlLabel
                      control={
                        <CheckBox
                          id={sub}
                          onChange={handleMultiSelectItem}
                          name={sub}
                          defaultChecked={checkedItemList.includes(sub)}
                        ></CheckBox>
                      }
                      className={classes.capitalize}
                      label={sub}
                    />
                  </ListItem>
                );
              })
              : null}
          </List>
        </FormGroup>
      </div>
    </Grid>
  );
};

export default Multiselect;
