import * as Yup from 'yup';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';
import axios from 'axios';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { trackPromise } from 'react-promise-tracker';
import { areas } from 'src/utils/areas';

const useStyles = makeStyles({
  orStyle: {
    textAlign: 'center'
  }
});

function AddRecentEntryForm({ open, onAddNew, onCancel }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add recent entry</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            id: '',
            type: '',
            name: '',
            isNew: false
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .trim()
              .max(255)
              .required('Name is required'),
            id: Yup.string()
              .trim()
              .max(255)
              .required('Id is required'),
            type: Yup.string()
              .trim()
              .max(255)
              .required('Type is required')
          })}
          onSubmit={values => {
            onAddNew(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography>Type:</Typography>
              <select
                name="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                className="select-input"
              >
                <option value="" label="Select type">
                  Select type
                </option>
                <option value="Person" label="Person">
                  Person
                </option>
                <option value="Group" label="Group">
                  Group
                </option>
                <option value="Family" label="Family">
                  Family
                </option>
              </select>
              {errors.type && (
                <div className="input-feedback">{errors.type}</div>
              )}
              <TextField
                error={Boolean(touched.id && errors.id)}
                margin="dense"
                id="id"
                label="Id"
                type="text"
                name="id"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.id && errors.id}
                onBlur={handleBlur}
                value={values.id}
              />

              <TextField
                error={Boolean(touched.name && errors.name)}
                margin="dense"
                id="name"
                label="Name"
                type="text"
                name="name"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                value={values.name}
              />

              <FormControlLabel
                className="is-new-checkbox"
                control={
                  <Checkbox
                    checked={values.isNew}
                    onChange={() => setFieldValue('isNew', !values.isNew)}
                    name="isNew"
                    color="primary"
                  />
                }
                label="Is New?"
              />

              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  onClick={onCancel}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Add
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

AddRecentEntryForm.propTypes = {
  open: PropTypes.bool,
  onAddNew: PropTypes.func,
  onCancel: PropTypes.func
};

export default AddRecentEntryForm;
