import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';

import {
  Button, Card,
  CardActions,
  CardContent,
  CardHeader, Container,
  Divider,
  IconButton,
  List, makeStyles, Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import axios from 'axios';
import clsx from 'clsx';
import {
  Edit,
  Eye,
  EyeOff,
  Home,
  Paperclip,
  Plus as PlusIcon
} from 'react-feather';
import { trackPromise } from 'react-promise-tracker';
import LanguageIcon from 'src/components/LanguageIcon';
import Page from 'src/components/Page';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';
import { Spinner } from 'src/components/Spinner/Spinner';
import { areas } from 'src/utils/areas';
import Toast from '../../components/Toast';
import AddBookInCollectionForm from './AddBookInCollectionForm';
import EditCollectionForm from './EditCollectionForm';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  rootTile: {
    display: 'flex',
    marginBottom: '20px',
    padding: 20
  },
  image: {
    height: 48,
    width: 48
  },
  cover: {
    width: 200,
    height: 'auto'
  },
  content: {
    flexGrow: 1
  },
  pageRoot: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  flexGap: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));

const SingleCollection = () => {
  const params = useParams();
  const classes = useStyles();
  const collectionId = params.collectionId;

  const [dataReqMade, setDataReqMade] = useState(false);
  const [data, setData] = useState();
  const [bookList, setBookList] = useState([]);
  const [success, setSuccess] = useState('');
  const [popup, setPopup] = useState(false);
  const [addArtistPopup, setAddArtistPopup] = useState(false);

  const onCancel = () => {
    setPopup(false);
  };

  const onAddArtistPopupCancel = () => {
    setAddArtistPopup(false);
  };

  const loadData = () => {
    trackPromise(
      axios
        .get(`${constants.SERVER_HOST_NAME}/collection/${collectionId}`)
        .then(res1 => {
          setDataReqMade(true);
          if (res1.status === 200) {
            setData(res1.data);
            setBookList(res1.data?.artists);
          }

          // setTypesMulti(res1.data.packagetype);
          // console.log(typesMulti);
        })
        .catch(err => {
          setSuccess('error');
          console.log(err);
        }),
      areas.inside
    );
  };

  const onAddBook = bookId => {
    trackPromise(
      axios
        .post(
          `${constants.SERVER_HOST_NAME}/backoffice/collection/${collectionId}/${bookId}`,
          helpers.getJWTHeader()
        )
        .then(res => {
          if (res.status === 200) {
            if (res.data === 'Book not found!') {
              setSuccess('notFound');
            } else if (res.data?.id !== null && res.data?.id !== '') {
              setSuccess('added');
              loadData();
              onAddArtistPopupCancel();
            }
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };

  const onAddCollectionData = collection => {
    setSuccess(undefined);
    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME +
            '/backoffice/collection/saveCollectionData',
          collection,
          helpers.getJWTHeader()
        )
        .then(response => {
          setDataReqMade(true);
          if (response.status === 200) {
            setSuccess('success');
            setPopup(false);

            setData(response.data);
            setBookList(response.data?.artists);
          } else {
            setSuccess('notSaved');
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };

  const removeBook = event => {
    const productId = event.currentTarget.id;
    trackPromise(
      axios
        .delete(
          `${constants.SERVER_HOST_NAME}/backoffice/collection/${collectionId}/${productId}`,
          helpers.getJWTHeader()
        )
        .then(res => {
          if (res.status === 200) {
            setSuccess('removed');
            setBookList(bookList.filter(product => product.id !== productId));
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };

  const toggleAccessible = () => {
    if (!data) return;

    data.isAccessible = !data.isAccessible;
    setData(data);
    onAddCollectionData(data);
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <Page title={data?.name ?? 'Collection'} className={classes.pageRoot}>
      <Container maxWidth={false}>
        <Card className={clsx(classes.root)}>
          {success === 'success' ? (
            <Toast status={true} type="success" message="Successful!" />
          ) : (
            ''
          )}
          {success === 'error' ? (
            <Toast status={true} type="error" message="Error!" />
          ) : (
            ''
          )}
          {success === 'notSaved' ? (
            <Toast status={true} type="warning" message="Save Unsuccessful!" />
          ) : (
            ''
          )}
          {success === 'notFound' ? (
            <Toast status={true} type="warning" message="Artist not Found!" />
          ) : (
            ''
          )}
          {success === 'added' ? (
            <Toast status={true} type="success" message="Artist Added!" />
          ) : (
            ''
          )}
          {success === 'removed' ? (
            <Toast status={true} type="success" message="Artist Removed!" />
          ) : (
            ''
          )}
          <CardHeader
            title={
              <div>
                <h3>
                  <LanguageIcon language="EN"></LanguageIcon>
                  {data?.name}
                </h3>
                <h3>
                  <LanguageIcon language="DE"></LanguageIcon>
                  {data?.nameDE}
                </h3>
              </div>
            }
            className="header-div"
          />
          <Divider />
          <Typography variant="h5" style={{ padding: '1.2rem' }}>
            {'Collection Type: ' + data?.collectionType}
          </Typography>
          <Divider />

          <Spinner area={areas.inside} />
          <OuterSpinner area={areas.outside} />
          <CardContent>
            <div className={classes.flexGap}>
              <Button onClick={e => setPopup(true)}>
                <div className={classes.flexGap}>
                  <Edit />
                  {'Edit Collection'}
                </div>
              </Button>
              <Button onClick={toggleAccessible}>
                <div className={classes.flexGap}>
                  {data?.isAccessible === true ? (
                    <Eye></Eye>
                  ) : (
                    <EyeOff> </EyeOff>
                  )}
                  {data?.isAccessible === true ? 'Published' : 'Draft'}
                </div>
              </Button>

              {data?.isHomepageBanner === true && (
                <Button>
                  <div className={classes.flexGap}>
                    <Paperclip></Paperclip>
                    {'Homepage Banner'}
                  </div>
                </Button>
              )}

              {data?.isHomepageCollection === true && (
                <Button component={RouterLink} to="/app/homepage-collections/">
                  <div className={classes.flexGap}>
                    <Home></Home>
                    {'Homepage Collection'}
                  </div>
                </Button>
              )}
            </div>
            <Divider className="content-divider" />
            <div className="content-div">
              <Typography
                className="content-title"
                color="primary"
                gutterBottom
              >
                Description:
              </Typography>
            </div>
            <div className="content-div">
              <Typography className="content-text" gutterBottom>
                <LanguageIcon language="EN"></LanguageIcon>
                {data?.description}
              </Typography>
            </div>
            <div className="content-div">
              <Typography className="content-text" gutterBottom>
                <LanguageIcon language="DE"></LanguageIcon>
                {data?.descriptionDE}
              </Typography>
            </div>
            <Divider className="content-divider" />
            <div className="content-div">
              <Typography
                className="content-title"
                color="primary"
                gutterBottom
              >
                Image:
              </Typography>
              <img
                src={data?.imageURL}
                alt={data?.name}
                className="collection-image"
              />
            </div>
            <Divider className="content-divider" />
            <div className="content-div">
              <Typography
                className="content-title"
                color="primary"
                gutterBottom
              >
                Image Credit:
              </Typography>
            </div>
            <div className="content-div">
              <Typography className="content-text" gutterBottom>
                <LanguageIcon language="EN"></LanguageIcon>
                {data?.imageCredit}
              </Typography>
            </div>
            <div className="content-div">
              <Typography className="content-text" gutterBottom>
                <LanguageIcon language="DE"></LanguageIcon>
                {data?.imageCreditDE}
              </Typography>
            </div>
            <Divider className="content-divider" />
            <div className="content-div artists-div">
              <Typography
                className="content-title"
                color="primary"
                gutterBottom
              >
                Artists:
              </Typography>
              <Button onClick={e => setAddArtistPopup(true)}>
                <PlusIcon />
                Add Artist
              </Button>
            </div>

            {(bookList === null || bookList?.length === 0) && dataReqMade ? (
              <Typography variant="h4">No items found</Typography>
            ) : (
              <List>
                {bookList?.map((product, i) => (
                  <Card className={classes.rootTile} key={product.id}>
                    <CardContent className={classes.content}>
                      <Typography
                        className={classes.name}
                        color="primary"
                        gutterBottom
                      >
                        {product.name}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.action}>
                      <IconButton
                        edge="end"
                        size="small"
                        id={product?.id}
                        onClick={e => removeBook(e)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                ))}
              </List>
            )}

            <Divider />
          </CardContent>
        </Card>
        <AddBookInCollectionForm
          open={addArtistPopup}
          onCancel={onAddArtistPopupCancel}
          onAddNew={onAddBook}
        />
        <EditCollectionForm
          open={popup}
          collection={data}
          onCancel={onCancel}
          onAddNew={onAddCollectionData}
        />
      </Container>
    </Page>
  );
};

export default SingleCollection;
