import React, { useState } from 'react';
import {
    makeStyles,
    Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    root: {
    },
});

function Toast(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.status ?? false);


    const handleCloseToast = (event, reason) => {
        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={props.autoHideDuration ?? 6000} onClose={handleCloseToast}>
            <Alert onClose={handleCloseToast} severity={props.type}>
                {props.message}
            </Alert>
        </Snackbar>

    )
};

export default Toast;
