import * as Yup from 'yup';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  orStyle: {
    textAlign: 'center'
  }
});

function EditSelectedForm({ open, onAddNew, onCancel, oldname }) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit collection</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: oldname ?? ''
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .trim()
              .max(255)
              .required('Name is required')
          })}
          onSubmit={values => {
            onAddNew(values.name);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                margin="dense"
                id="name"
                label="Collection Name"
                type="text"
                name="name"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                value={values.name}
              />
              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  onClick={onCancel}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

EditSelectedForm.propTypes = {
  open: PropTypes.bool,
  onAddNew: PropTypes.func,
  onCancel: PropTypes.func,
  oldname: PropTypes.string
};
export default EditSelectedForm;
