import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Settings,
  BarChart,
  Bookmark,
  Home as HomeIcon,
  Search as SearchIcon
} from 'react-feather';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import NavItem from './NavItem';
import PropTypes from 'prop-types';

const user = {
  avatar: '/static/images/avatars/avatar_7.png',
  jobTitle: 'Web Developer',
  name: 'Mahesh'
};

const items = [
  // {
  //   href: '/app/dashboard',
  //   icon: BarChartIcon,
  //   title: 'Dashboard'
  // },
  {
    href: '/app/search',
    icon: SearchIcon,
    title: 'Search'
  },
  {
    href: '/app/collections',
    icon: Bookmark,
    title: 'Collections'
  },
  // {
  //   href: '/app/banner-collection',
  //   icon: HomeIcon,
  //   title: 'Homepage Banner Collection'
  // },
  {
    href: '/app/homepage-collections',
    icon: HomeIcon,
    title: 'Homepage Collections'
  },
  {
    href: '/app/recent-entries',
    icon: BarChart,
    title: 'Recent Entries'
  },
  {
    href: '/app/recent-entries-order',
    icon: Settings,
    title: 'Recent Entries Order'
  },
  {
    href: '/app/free-homepage-entries',
    icon: Settings,
    title: 'Free Homepage Entries'
  }

  // {
  //   href: '/app/highlight-articles',
  //   icon: CheckIcon,
  //   title: 'Highlight Articles'
  // },
  // {
  //     href: '/app/subjects',
  //     icon: ShoppingBagIcon,
  //     title: 'Subjects'
  // },
  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products'
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account'
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {/* <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider /> */}
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
