import * as constants from '../../../utils/constants';
import * as helpers from '../../../utils/helper_functions';

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Menu as MenuIcon,
  PlusSquare as PlusIcon,
  Lock as LockIcon,
  Unlock as UnlockIcon
} from 'react-feather';
import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import Toast from '../../../components/Toast';
import { areas } from 'src/utils/areas';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginBottom: '20px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14,
    textTransform: 'capitalize'
  },
  pos: {
    marginBottom: 12
  },
  cover: {
    minWidth: 100,
    maxWidth: 100,
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  content: {
    flexGrow: 1
  },
  action: {}
});

const SearchTile = props => {
  var entryType = props?.item.content.entryType;
  const [success, setSuccess] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const [item, setItem] = useState(props?.item);

  const toggleFreeAccess = async () => {
    setSuccess(undefined);
    console.log(item?.content);
    const id = item?.content.id;
    const newValue = !item?.content?.isFreeAccess;
    const url =
      constants.SERVER_HOST_NAME +
      `/backoffice/book/${id}/set-is-free-access/${newValue}`;
    console.log(url);
    try {
      await axios.get(url, helpers.getJWTHeader());
      setSuccess('success');
      setItem({
        ...item,
        content: { ...item.content, isFreeAccess: newValue }
      });
    } catch (error) {
      setSuccess('error');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onAddNewRecentEntry = (item, isRecent = false) => {
    const values = {
      id: item.content.id,
      type: item.content.entryType,
      name: item.content.name,
      isNew: isRecent
    };
    setSuccess(undefined);
    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME + '/backoffice/recentEntry',
          values,
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200) {
            setSuccess('added');
          } else {
            setSuccess('error');
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.cover}>
        {success === 'success' ? (
          <Toast status={true} type="success" message="Successful!" />
        ) : (
          ''
        )}
        {success === 'error' ? (
          <Toast status={true} type="error" message="Error!" />
        ) : (
          ''
        )}
        {success === 'added' ? (
          <Toast status={true} type="success" message="Recent Entry Added!" />
        ) : (
          ''
        )}
        <img
          src={
            item?.content?.imageURL ?? '/static/images/cards/default_artist.png'
          }
          alt={item?.content?.name}
        />
      </CardMedia>
      <CardContent className={classes.content}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {item?.content?.entryType}
        </Typography>
        {entryType === 'Person' ? (
          <Link to={`/app/entry?id=${item.id}`}>
            <Typography variant="h5" component="h2">
              {item?.content?.name}
            </Typography>
          </Link>
        ) : (
          <Typography variant="h5" component="h2">
            {item?.content?.name}
          </Typography>
        )}
      </CardContent>
      <CardActions className={classes.action}>
        <div>
          {item?.content?.entryType == 'Person' && (
            <IconButton onClick={toggleFreeAccess}>
              {item?.content?.isFreeAccess ? (
                <UnlockIcon style={{ color: 'green' }} />
              ) : (
                <LockIcon style={{ color: 'red' }} />
              )}
            </IconButton>
          )}
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {entryType === 'Person' && (
              <MenuItem
                id={item?.id + 'goto'}
                component={Link}
                to={`/app/entry?id=${item?.id}`}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit Data" />
              </MenuItem>
            )}
            <MenuItem
              id={item?.id + 'rev'}
              onClick={() => onAddNewRecentEntry(item, true)}
            >
              <ListItemIcon>
                <PlusIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Add to Recently Added" />
            </MenuItem>
            <MenuItem
              id={item.id + 'revr'}
              onClick={() => onAddNewRecentEntry(item)}
            >
              <ListItemIcon>
                <PlusIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Add to Recently Edited" />
            </MenuItem>
          </Menu>
        </div>
      </CardActions>
    </Card>
  );
};

export default SearchTile;
