import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import * as constants from '../../utils/constants';
import clsx from 'clsx';
import Toast from '../../components/Toast';
import * as helpers from '../../utils/helper_functions';
import { trackPromise } from 'react-promise-tracker';
import { Spinner } from 'src/components/Spinner/Spinner';
import { areas } from 'src/utils/areas';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';
import { Edit as EditIcon } from 'react-feather';
import EditSelectedForm from './EditSelectedForm';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  },
  capitalize: {
    textTransform: 'capitalize'
  }
});

function SelectedData() {
  const [success, setSuccess] = useState('');

  const [popup, setPopup] = useState(false);

  const onCancel = () => {
    setPopup(false);
  };
  const onAddNewCollection = collectionName => {
    setSuccess(undefined);
    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME +
            '/backoffice/collection/saveCollectionData',
          {
            id: collectionId,
            collectionName: collectionName,
            isHomepageCollection: true
          },
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200) {
            setSuccess(true);
            setPopup(false);
            loadData();
          } else {
            setSuccess(false);
          }
        })
        .catch(error => {
          setSuccess(false);
          console.log(error);
        }),
      areas.outside
    );
  };
  const removeFromHomepage = event => {
    const cid = event.currentTarget.id;
    var finalUrl = `/backoffice/collection/homepageCollections/${cid}`;
    console.log(finalUrl);

    trackPromise(
      axios
        .delete(constants.SERVER_HOST_NAME + finalUrl, helpers.getJWTHeader())
        .then(res => {
          if (res.status === 200) {
            loadData();
            setSuccess('success');
            console.log(res.data);
          } else {
            setSuccess('failed');
          }
        })
        .catch(error => {
          setSuccess('failed');
          console.log(error);
        }),
      areas.outside
    );
  };

  const [data, setData] = useState();
  const [collectionId, setCollectionId] = useState();
  const [collectionName, setCollectionName] = useState();
  const loadData = () => {
    trackPromise(
      axios
        .get(constants.SERVER_HOST_NAME + '/collection/homepageBanner')
        .then(res1 => {
          setData(res1.data);
          setCollectionName(res1.data.object?.collectionName);
          setCollectionId(res1.data.object?.collectionId);
          // setTypesMulti(res1.data.packagetype);
          // console.log(typesMulti);
        })
        .catch(err => console.log(err)),
      areas.inside
    );
  };
  useEffect(() => {
    loadData();
  }, []);
  const classes = useStyles();
  console.log(data);

  return (
    <Card className={clsx(classes.root)}>
      {success === 'success' ? (
        <Toast status={true} type="success" message="Successful!" />
      ) : (
        ''
      )}
      {success === 'error' ? (
        <Toast status={true} type="error" message="Error!" />
      ) : (
        ''
      )}
      <CardHeader
        title={collectionName}
        action={
          <IconButton aria-label="settings" onClick={e => setPopup(true)}>
            <EditIcon />
          </IconButton>
        }
      />
      <Divider />
      <Spinner area={areas.inside} />
      <OuterSpinner area={areas.outside} />

      <List>
        {data?.map((product, i) => (
          <ListItem divider={i < data.length - 1} key={product.id}>
            <ListItemText
              primary={product.name}
              secondary={product.description}
              className={classes.capitalize}
            />
            <IconButton
              edge="end"
              size="small"
              id={product.id}
              onClick={e => removeFromHomepage(e)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <EditSelectedForm
        open={popup}
        onCancel={onCancel}
        onAddNew={onAddNewCollection}
        oldname={collectionName}
      />
    </Card>
  );
}

export default SelectedData;
