import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';

import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import AddRecentEntryForm from './AddRecentEntryForm';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';
import { Plus as PlusIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner/Spinner';
import Toast from '../../components/Toast';
import { areas } from 'src/utils/areas';
import axios from 'axios';
import clsx from 'clsx';
import { trackPromise } from 'react-promise-tracker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  },
  capitalize: {
    textTransform: 'capitalize'
  }
});

function CollectionsList() {
  const [data, setData] = useState();
  const [success, setSuccess] = useState('');
  const [popup, setPopup] = useState(false);

  const onCancel = () => {
    setPopup(false);
  };

  const onAddNewRecentEntry = values => {
    setSuccess(undefined);
    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME + '/backoffice/recentEntry',
          values,
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200) {
            setSuccess('added');
            setPopup(false);
            setData(response.data);
          } else {
            setSuccess('addFailed');
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };

  const deleteRecentEntry = id => {
    trackPromise(
      axios
        .delete(
          `${constants.SERVER_HOST_NAME}/backoffice/recentEntry/deleteFromRecentEntries?id=${id}`,
          helpers.getJWTHeader()
        )
        .then(res => {
          if (res.status === 200 && res.data === 'Successful') {
            setSuccess('deleted');
            handleMoreMenuClose();
            setData(data.filter(collection => collection.id !== id));
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };

  const loadData = () => {
    trackPromise(
      axios
        .get(constants.SERVER_HOST_NAME + '/recentEntry/recentEntryArray')
        .then(res1 => {
          if (res1.status === 200) {
            setData(res1.data);
          }
          // setTypesMulti(res1.data.packagetype);
          // console.log(typesMulti);
        })
        .catch(err => console.log(err)),
      areas.inside
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState('');

  const handleMoreButtonClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
    console.log(open);
  };
  const handleMoreMenuClose = () => {
    setAnchorEl(null);
    setOpen('');
  };

  const toggleNewTag = (recentEntry) => {
    trackPromise(
      axios
        .put(
          `${constants.SERVER_HOST_NAME}/backoffice/recentEntry/set-is-new?id=${recentEntry.id}&isNew=${!recentEntry?.isNew}`,
          {},
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200) {
            loadData();
            setSuccess('success');
          } else {
            setSuccess('error');
          }
        })
        .catch(error => {
          setSuccess('error');
          console.error(error);
        }),
      areas.outside
    );
  }

  return (
    <Card className={clsx(classes.root)}>
      {success === 'success' ? (
        <Toast status={true} type="success" message="Successful!" />
      ) : (
        ''
      )}
      {success === 'error' ? (
        <Toast status={true} type="error" message="Error!" />
      ) : (
        ''
      )}
      {success === 'added' ? (
        <Toast status={true} type="success" message="Recent Entry Added!" />
      ) : (
        ''
      )}
      {success === 'addFailed' ? (
        <Toast
          status={true}
          type="warning"
          message="Failed to Add Recent Entry!"
        />
      ) : (
        ''
      )}
      {success === 'deleted' ? (
        <Toast status={true} type="success" message="Recent Entry Deleted!" />
      ) : (
        ''
      )}

      <CardHeader
        title="Recent Entries"
        action={
          <IconButton aria-label="settings" onClick={e => setPopup(true)}>
            <PlusIcon />
          </IconButton>
        }
      />
      <Divider />
      <Spinner area={areas.inside} />
      <OuterSpinner area={areas.outside} />

      <List>
        {data?.map((recentEntry, i) => (
          <ListItem divider={i < data.length - 1} key={recentEntry?.id}>
            <ListItem>
              <ListItemText
                primary={
                  recentEntry?.name + (recentEntry?.isNew ? ' (New)' : '')
                }
                secondary={recentEntry?.type}
                className={classes.capitalize}
              />
            </ListItem>

            <IconButton
              aria-label="more"
              id={'more-button_' + recentEntry?.id}
              aria-controls={
                open === recentEntry?.id
                  ? 'more-menu_' + recentEntry?.id
                  : undefined
              }
              aria-expanded={open === recentEntry?.id ? 'true' : undefined}
              aria-haspopup="true"
              edge="end"
              size="small"
              onClick={event => handleMoreButtonClick(event, recentEntry?.id)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={'more-menu_' + recentEntry?.id}
              MenuListProps={{
                'aria-labelledby': 'option-button'
              }}
              anchorEl={anchorEl}
              open={open === recentEntry?.id}
              onClose={event => handleMoreMenuClose()}
              PaperProps={{
                style: {
                  boxShadow: 'none',
                  border: 'solid 0.5px',
                  borderRadius: '0px'
                }
              }}
            >
              <MenuItem onClick={() => {
                handleMoreMenuClose();
                toggleNewTag(recentEntry)
              }}>
                {recentEntry?.isNew ? 'Remove New Tag' : 'Add New Tag'}
              </MenuItem>
              <MenuItem onClick={() => deleteRecentEntry(recentEntry?.id)}>
                Delete
              </MenuItem>
            </Menu>
          </ListItem>
        ))}
      </List>
      <AddRecentEntryForm
        open={popup}
        onCancel={onCancel}
        onAddNew={onAddNewRecentEntry}
      />
    </Card>
  );
}

export default CollectionsList;
