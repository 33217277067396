import { Container, makeStyles } from '@material-ui/core';

import Page from 'src/components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';
import clsx from 'clsx';

import { Link as RouterLink } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Toast from 'src/components/Toast';
import { Spinner } from 'src/components/Spinner/Spinner';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';
import { areas } from 'src/utils/areas';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  listPaper: {
    margin: '20px',
    padding: '10px',
    maxWidth: '350px'
  }
}));

const RecentEntriesOrder = () => {
  const classes = useStyles();
  const [RecentEntries, setRecentEntries] = useState([]);

  const [success, setSuccess] = useState('');

  const loadData = () => {
    trackPromise(
      axios
        .get(constants.SERVER_HOST_NAME + '/recentEntry/recentEntryArray')
        .then(res1 => {
          if (res1.status === 200) {
            setRecentEntries(res1.data);
          }
          // setTypesMulti(res1.data.packagetype);
          // console.log(typesMulti);
        })
        .catch(err => console.log(err)),
      areas.inside
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleOnDragEnd = result => {
    if (!result.destination) return;
    const items = Array.from(RecentEntries);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setRecentEntries(items);
  };

  const saveOrder = () => {
    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME +
            '/backoffice/recentEntry/recentEntriesOrder',
          RecentEntries
        )
        .then(res => {
          if (res.status === 200) {
            setSuccess('success');
          }
        })
        .catch(error => {
          setSuccess('failed');
          console.log(error.message);
        }),
      areas.outside
    );
  };

  return (
    <Page title="Recent Entries Order" className={classes.root}>
      <Container maxWidth={false}>
        <Card className={clsx(classes.root)}>
          {success === 'success' ? (
            <Toast
              status={true}
              type="success"
              message="Order Saved Successfully!"
            />
          ) : (
            ''
          )}
          {success === 'failed' ? (
            <Toast status={true} type="error" message="Failed to Save Order!" />
          ) : (
            ''
          )}
          <CardHeader
            title="Recent Entries Order"
            action={
              <Button aria-label="saveOrder" onClick={saveOrder}>
                Save Order
              </Button>
            }
          />
          <Divider />
          <Spinner area={areas.inside} />
          <OuterSpinner area={areas.outside} />
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="recent-entries">
              {provided => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {RecentEntries?.map((recentEntry, i) => (
                    <Draggable
                      key={recentEntry?.id}
                      draggableId={recentEntry?.id}
                      index={i}
                    >
                      {provided => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.listPaper}
                        >
                          {/* <ListItem
                            // divider={i < RecentEntries.length - 1}
                           
                          > */}
                          <Typography>{recentEntry?.name}</Typography>
                          {/* </ListItem> */}
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
      </Container>
    </Page>
  );
};

export default RecentEntriesOrder;
