import AccountView from 'src/views/account/AccountView';
import Entry from './views/entry/Entry';
import Collections from './views/collection/Collections';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DashboardView from 'src/views/reports/DashboardView';
import HighlightedArticles from './views/highlightedArticles/HighlightedArticles';
import HomepageSectionView from 'src/views/homepage/HomepageSectionView';
import LoginView from 'src/views/auth/LoginView';
import MainLayout from 'src/layouts/MainLayout';
import { Navigate } from 'react-router-dom';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import React from 'react';
import RegisterView from 'src/views/auth/RegisterView';
import Selected from 'src/views/selected/Selected';
import SettingsView from 'src/views/settings/SettingsView';
import SingleCollection from './views/collection/Collection';
import Subjects from './views/subjects/Subjects';
import HomepageCollections from './views/collection/HomepageCollections';
import RecentEntries from './views/recentEntries/RecentEntries';
import RecentEntriesOrder from './views/recentEntries/RecentEntriesOrder';
import FreeHomepageEntries from './views/freeEntries/FreeHomepageEntries';

const routes = isLoggedIn => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'search', element: <HomepageSectionView /> },
      { path: 'subjects', element: <Subjects /> },
      // { path: 'banner-collection', element: <Selected /> },
      { path: 'homepage-collections', element: <HomepageCollections /> },
      { path: 'collections/:collectionId', element: <SingleCollection /> },
      { path: 'collections', element: <Collections /> },
      { path: 'recent-entries', element: <RecentEntries /> },
      { path: 'recent-entries-order', element: <RecentEntriesOrder /> },
      { path: 'free-homepage-entries', element: <FreeHomepageEntries /> },
      { path: 'highlight-articles', element: <HighlightedArticles /> },
      // { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'entry', element: <Entry /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: isLoggedIn ? <Navigate to="/app/search" /> : <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
