import axios from "axios";
import * as constants from "./constants"

var dateFormat = require("dateformat");

export function getPublishYear(publishDate) {
  return publishDate?.toString()?.slice(0, 4);
}

export function getPublishedDate(publishDate) {
  if (publishDate === undefined) {
    return;
  }
  return dateFormat(publishDate, "dd mmm yyyy");
}

export function getProductURL(packageType, id) {
  var url = "";
  if (packageType?.toUpperCase() === "JOURNAL") {
    url = "/journal?id=" + id;
  } else if (packageType?.toUpperCase() === "BOOK") {
    url = "/books?id=" + id;
  } else if (packageType?.toUpperCase() === "CHAPTER") {
    url = "/chapter?id=" + id;
  } else if (packageType?.toUpperCase() === "ARTICLE") {
    url = "/article?id=" + id;
  } else if (packageType?.toUpperCase() === "ISSUES") {
    url = "/issues?id=" + id;
  }
  else if (packageType?.toUpperCase() === "SERIES") {
    url = "/series?id=" + id;
  }
  return url;
}


export function getDownloadOptions(product) {
  var opt = [];
  if (product?.pdfLink) {
    opt.push({
      title: "Download PDF",
      downloadType: "PDF",
    });
  }
  if (product?.epubLink) {
    opt.push(
      {
        title: "Download ePub",
        downloadType: "EPub",
      });
  }
  if (product?.mobiLink) {
    opt.push(
      {
        title: "Download mobi",
        downloadType: "Mobi",
      });
  }
  opt.push(
    {
      title: "Download cover",
      downloadType: "Cover",
    },
    {
      title: "Download flyer",
      downloadType: "Flyer",
    },
    {
      title: "Download Press Release",
      downloadType: "PressRelease",
    },
  )
  return opt;
}

export async function uploadFile(file) {
  var url = "";
  const data = new FormData()
  data.append('file', file)

  const response = await axios.post(constants.SERVER_HOST_NAME + '/upload/file', data);
  if (response.status === 200) {
    console.log('Successfully uploaded file');
    url = response.data;
  }
  return url;
}

export function getJWTHeader() {
  return {
    header: {
      'Authorization': localStorage.getItem('Authorization')
    }
  };
}

export function getTitle(product) {
  if(product?.DescriptiveDetail?.TitleDetail === undefined || product?.DescriptiveDetail?.TitleDetail === null) {
    return "";
  }
  if(Array.isArray(product.DescriptiveDetail.TitleDetail) && product.DescriptiveDetail.TitleDetail.length > 0) {
    return product.DescriptiveDetail.TitleDetail[0]?.TitleElement?.TitleText
  }
  return product?.DescriptiveDetail?.TitleDetail?.TitleElement?.TitleText ?? ""
}