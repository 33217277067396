import * as Yup from 'yup';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  FormControl,
  TextField
} from '@material-ui/core';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@mui/material';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import { areas } from 'src/utils/areas';
import * as constants from '../../utils/constants';
import LanguageIcon from 'src/components/LanguageIcon';

const useStyles = makeStyles({
  orStyle: {
    textAlign: 'center'
  }
});

function EditCollectionForm({ open, onAddNew, onCancel, collection }) {
  const classes = useStyles();
  const [Image, setImage] = useState({
    picturePreview: '',
    pictureAsFile: null
  });
  const [ImageURL, setImageURL] = useState('');

  const handleImageChange = e => {
    setImage({
      /* contains the preview, if you want to show the picture to the user
           you can access it with this.state.currentPicture
       */
      picturePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      pictureAsFile: e.target.files[0]
    });
  };

  const uploadImage = setFieldValue => {
    const formData = new FormData();
    formData.append('imageFile', Image.pictureAsFile);

    // for (var key of formData.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    // }

    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME + '/backoffice/collection/uploadImage',
          formData,
          { 'Content-Type': 'multipart/form-data' }
        )
        .then(res => {
          if (res.data !== null && res.data !== '') {
            setImageURL(res.data);
            setFieldValue('imageURL', res.data);
          }
        })
        .catch(err => console.log(err.message)),
      areas.outside
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle variant="h3" id="form-dialog-title">
        Edit collection
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            id: collection?.id,
            collectionType: collection?.collectionType,
            name: collection?.name,
            nameDE: collection?.nameDE,
            description: collection?.description,
            descriptionDE: collection?.descriptionDE,
            imageURL: collection?.imageURL,
            artists: collection?.artists,
            isHomepageBanner: collection?.isHomepageBanner,
            isHomepageCollection: collection?.isHomepageCollection,
            isAccessible: collection?.isAccessible,
            imageCredit: collection?.imageCredit,
            imageCreditDE: collection?.imageCreditDE
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .trim()
              .max(255)
              .required('Name is required'),
            nameDE: Yup.string()
              .trim()
              .max(255)
              .required('Name is required'),
            collectionType: Yup.string()
              .trim()
              .max(255)
              .required('Collection Type is required'),
            description: Yup.string()
              .trim()
              .max(255)
              .required('Description is required')
          })}
          onSubmit={values => {
            onAddNew(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <InputLabel id="collectionTypeLabel">
                  <span style={{ marginLeft: '1.25rem' }}>Collection Type</span>
                </InputLabel>
                <Select
                  labelId="collectionTypeLabel"
                  name="collectionType"
                  label="Collection Type"
                  variant="outlined"
                  value={values.collectionType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="select-input"
                  placeholder="Select type"
                >
                  {/* <MenuItem value="" label="Select type">
                    Select type
                  </MenuItem> */}
                  <MenuItem value="template" label="Template">
                    template
                  </MenuItem>
                  <MenuItem value="free" label="Free">
                    free
                  </MenuItem>
                </Select>
                {errors.collectionType && (
                  <div className="input-feedback">{errors.collectionType}</div>
                )}
              </FormControl>

              <TextField
                error={Boolean(touched.name && errors.name)}
                variant="outlined"
                margin="normal"
                id="name"
                label={
                  <span>
                    <LanguageIcon language="EN" />
                    Name
                  </span>
                }
                type="text"
                name="name"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                value={values.name}
              />
              <TextField
                error={Boolean(touched.nameDE && errors.nameDE)}
                variant="outlined"
                margin="normal"
                id="nameDE"
                label={
                  <span>
                    <LanguageIcon language="DE" />
                    Name
                  </span>
                }
                type="text"
                name="nameDE"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.nameDE && errors.nameDE}
                onBlur={handleBlur}
                value={values.nameDE}
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                variant="outlined"
                margin="normal"
                id="description"
                label={
                  <span>
                    <LanguageIcon language="EN" />
                    Description
                  </span>
                }
                type="text"
                name="description"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                value={values.description}
              />
              <TextField
                error={Boolean(touched.descriptionDE && errors.descriptionDE)}
                variant="outlined"
                margin="normal"
                id="descriptionDE"
                label={
                  <span>
                    <LanguageIcon language="DE" />
                    Description
                  </span>
                }
                type="text"
                name="descriptionDE"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.descriptionDE && errors.descriptionDE}
                onBlur={handleBlur}
                value={values.descriptionDE}
              />
              <Typography sx={{ marginTop: '24px !important' }}>
                Image:
              </Typography>
              <Box
                sx={{
                  margin: '10px !important',
                  marginBottom: '24px !important'
                }}
              >
                <input
                  name="imageInput"
                  type="file"
                  onChange={handleImageChange}
                />
                <Button
                  variant="contained"
                  color="default"
                  onClick={() =>
                    Image.pictureAsFile !== null
                      ? uploadImage(setFieldValue)
                      : null
                  }
                >
                  Upload Image
                </Button>
                {ImageURL !== null && ImageURL.length > 0 ? (
                  <Typography>Image Uploaded!</Typography>
                ) : null}
              </Box>
              <TextField
                error={Boolean(touched.imageCredit && errors.imageCredit)}
                variant="outlined"
                margin="normal"
                id="imageCredit"
                label={
                  <span>
                    <LanguageIcon language="EN" />
                    Image Credit
                  </span>
                }
                type="text"
                name="imageCredit"
                fullWidth
                onChange={handleChange}
                helperText={touched.imageCredit && errors.imageCredit}
                onBlur={handleBlur}
                value={values.imageCredit}
              />
              <TextField
                error={Boolean(touched.imageCreditDE && errors.imageCreditDE)}
                variant="outlined"
                margin="normal"
                id="imageCreditDE"
                label={
                  <span>
                    <LanguageIcon language="DE" />
                    Image Credit
                  </span>
                }
                type="text"
                name="imageCreditDE"
                fullWidth
                onChange={handleChange}
                helperText={touched.imageCreditDE && errors.imageCreditDE}
                onBlur={handleBlur}
                value={values.imageCreditDE}
              />

              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  onClick={onCancel}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

EditCollectionForm.propTypes = {
  open: PropTypes.bool,
  onAddNew: PropTypes.func,
  onCancel: PropTypes.func,
  oldname: PropTypes.string
};
export default EditCollectionForm;
