import React, { useState, useRef } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  CardActions,
  CardMedia,
  Box,
  Button
} from '@material-ui/core';
import { Plus as PlusIcon } from 'react-feather';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import * as constants from '../../utils/constants';
import clsx from 'clsx';
import Toast from '../../components/Toast';
import PropTypes from 'prop-types';
import * as helpers from '../../utils/helper_functions';
import { trackPromise } from 'react-promise-tracker';
import { DropzoneArea } from 'material-ui-dropzone';
import { areas } from 'src/utils/areas';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  rootTile: {
    display: 'flex',
    marginBottom: '20px',
    padding: 20
  },
  image: {
    height: 48,
    width: 48
  },
  content: {
    flexGrow: 1
  }
});

function UploadImage({ imageURL, id, entryType, ...rest }) {
  const [success, setSuccess] = useState();
  const dropZoneAreaKey = useRef(1);
  const [data, setData] = useState(imageURL);
  const [file, setFile] = useState(null);

  const classes = useStyles();

  const removeImage = event => {
    setSuccess(undefined);

    trackPromise(
      axios
        .delete(
          constants.SERVER_HOST_NAME +
            '/backoffice/book/' +
            id +
            '/removeImage',
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200 && response.data === 'Successful') {
            setData(null);
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        })
        .catch(error => {
          setSuccess(false);
        }),
      areas.outside
    );
  };

  const saveImage = () => {
    setSuccess(undefined);
    let reqBody = new FormData();
    reqBody.append('imageFile', file[0]);
    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME + '/backoffice/book/' + id + '/addImage',
          reqBody,
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200) {
            setData(response.data);
            dropZoneAreaKey.current += 1;
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        })
        .catch(error => {
          setSuccess(false);
        }),
      areas.outside
    );
  };

  return (
    <Card className={clsx(classes.root)}>
      {success === true ? (
        <Toast status={true} type="success" message="Successful!" />
      ) : (
        ''
      )}
      {success === false ? (
        <Toast status={true} type="error" message="Error!" />
      ) : (
        ''
      )}
      <CardHeader title={'Upload Image'} />
      <Divider />

      {data ? (
        <Card className={classes.rootTile}>
          <img
            src={data}
            alt={'Artist'}
            style={{ width: '230px', aspectRatio: '1/1.4' }}
          />
          <CardActions className={classes.action}>
            <div>
              <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={e => removeImage(e)}
              >
                <DeleteIcon style={{ color: 'red' }}></DeleteIcon>
              </IconButton>
            </div>
          </CardActions>
        </Card>
      ) : null}
      <DropzoneArea
        key={dropZoneAreaKey.current}
        acceptedFiles={['image/*']}
        //showPreviews={true}
        maxFileSize={10485760}
        onChange={newFile => setFile(newFile)}
        filesLimit={1}
        showFileNames={true}
        dropzoneText={'Drag and drop image file here or click'}
      />
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button color="primary" variant="contained" onClick={saveImage}>
          Save
        </Button>
      </Box>
    </Card>
  );
}

UploadImage.propTypes = {
  imageURL: PropTypes.array,
  id: PropTypes.string,
  entryType: PropTypes.string
};

export default UploadImage;
