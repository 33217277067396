import React from 'react';

const LanguageIcon = ({ language }) => {
  return (
    <div
      style={{
        padding: '0.25em 0.5em',
        // backgroundColor: 'black',
        // color: 'white',
        display: 'inline-block',
        fontSize: '0.5em',
        transform: 'translateY(-0.25rem)',
        marginRight: '0.5em',
        border: '1px solid black',
        borderRadius: '0.25em'
      }}
    >
      {language}
    </div>
  );
};

export default LanguageIcon;
