import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Button,
  Input
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

// import AddRecentEntryForm from './AddRecentEntryForm';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';
import { Shuffle as ShuffleIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner/Spinner';
import Toast from '../../components/Toast';
import { areas } from 'src/utils/areas';
import axios from 'axios';
import clsx from 'clsx';
import { trackPromise } from 'react-promise-tracker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  flexGap: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center'
  }
});

function FreeHomepageEntriesList() {
  const [data, setData] = useState();
  const [success, setSuccess] = useState('');
  const [popup, setPopup] = useState(false);

  const onCancel = () => {
    setPopup(false);
  };

  const randomiseEntries = async () => {
    const endpoint = new URL(constants.SERVER_HOST_NAME + '/free/randomise');
    const params = new URLSearchParams();
    params.append('interval', data?.randomiseInterval || 1);
    const url = endpoint.toJSON() + '?' + params.toString();
    console.log(url);
    await axios.get(url);
    loadData();
  };

  const loadData = () => {
    const url =
      constants.SERVER_HOST_NAME + '/free/get-homepage-entries-object';
    trackPromise(
      axios
        .get(url)
        .then(res1 => {
          if (res1.status === 200) {
            setData(res1.data);
            console.log(JSON.stringify(res1.data, null, 2));
          }
          // setTypesMulti(res1.data.packagetype);
          // console.log(typesMulti);
        })
        .catch(err => console.log(err)),
      areas.inside
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState('');

  const handleMoreButtonClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
    console.log(open);
  };
  const handleMoreMenuClose = () => {
    setAnchorEl(null);
    setOpen('');
  };

  return (
    <Card className={clsx(classes.root)}>
      {success === 'success' ? (
        <Toast status={true} type="success" message="Successful!" />
      ) : (
        ''
      )}
      {success === 'error' ? (
        <Toast status={true} type="error" message="Error!" />
      ) : (
        ''
      )}
      {success === 'added' ? (
        <Toast status={true} type="success" message="Free Entry Added!" />
      ) : (
        ''
      )}
      {success === 'deleted' ? (
        <Toast status={true} type="success" message="Recent Entry Deleted!" />
      ) : (
        ''
      )}

      <CardHeader title="Free Homepage Entries"></CardHeader>
      <Divider />
      <CardContent>
        <div className={classes.flexGap} style={{ gap: '1.5rem' }}>
          <Button onClick={e => randomiseEntries()}>
            <div className={classes.flexGap}>
              <ShuffleIcon />
              {'Pick random free entries'}
            </div>
          </Button>
          <div style={{ flexGrow: 1 }}></div>
          <div>
            Change every&nbsp;
            <Input
              value={data?.randomiseInterval}
              autoComplete="off"
              style={{ width: '20px', textAlign: 'center' }}
              placeholder="x"
              textAlign={'center'}
              onChange={e => {
                setData({ ...data, randomiseInterval: e.target.value });
              }}
            ></Input>
            days
          </div>
          <div>
            Last updated: {new Date(data?.lastUpdated)?.toLocaleDateString()}
          </div>
        </div>
      </CardContent>
      <Spinner area={areas.inside} />
      <OuterSpinner area={areas.outside} />

      <List>
        {data?.freeEntries?.map((freeHomepageEntry, i) => (
          <ListItem divider={i < data.length - 1} key={freeHomepageEntry?.id}>
            <ListItem>
              <ListItemText
                primary={
                  freeHomepageEntry?.name +
                  (freeHomepageEntry?.isNew ? ' (New)' : '')
                }
                secondary={freeHomepageEntry?.type}
                className={classes.capitalize}
              />
            </ListItem>
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

export default FreeHomepageEntriesList;
