import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Container
} from '@material-ui/core';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import * as constants from '../../utils/constants';
import clsx from 'clsx';
import Toast from '../../components/Toast';
import Page from 'src/components/Page';
import * as helpers from '../../utils/helper_functions';
import { trackPromise } from "react-promise-tracker";
import { Spinner } from 'src/components/Spinner/Spinner';
import { areas } from 'src/utils/areas';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  },
  capitalize: {
    textTransform: 'capitalize',
  }
}));

function HighlightedArticles() {
  const [success, setSuccess] = useState("");
  const removeFromSelected = (event) => {
    const cid = event.currentTarget.id;
    var finalUrl = "/backoffice/highlightArticles?id=";
    finalUrl += cid;
    console.log(finalUrl);
    trackPromise(axios.delete(constants.SERVER_HOST_NAME + finalUrl,helpers.getJWTHeader())
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setSuccess("success");
          console.log(res.data);
        } else {
          setSuccess("failed");
        }

      }).catch((error) => {
        setSuccess("failed");
        console.log(error);
      }),
      areas.outside,      
      );

  }

  const [data, setData] = useState();
  const loadData = () => {
    trackPromise(axios.get(constants.SERVER_HOST_NAME + "/backoffice/highlightArticles",helpers.getJWTHeader()).then(res1 => {
      setData(res1.data);
      // setTypesMulti(res1.data.packagetype);
      // console.log(typesMulti);
    }).catch(err => console.log(err))
    ,
      areas.inside,  
      );
  }
  useEffect(() => {
    loadData();
  }, []);
  const classes = useStyles();
  console.log(data);

  return (
    <Page
      className={classes.pageRoot}
      title="Highlight Articles"
    >
      <Container maxWidth={false}>
      <Card
        className={clsx(classes.root)}
      >
        {
          (success === "success") ? <Toast status={true} type="success" message="Successful!" /> : ""
        }
        {
          (success === "error") ? <Toast status={true} type="error" message="Error!" /> : ""
        }
        <CardHeader
          title="Highlight Articles"
        />
        <Divider />
        <Spinner  area={areas.inside}/>
        <OuterSpinner area={areas.outside}/>
        <List>
          {data?.map((product, i) => (
            <ListItem
              divider={i < data.length - 1}
              key={product.id}
            >
              <ListItemText
                primary={product.articleData.name}
                secondary={product.packageType}
                className={classes.capitalize}
              />
              <IconButton
                edge="end"
                size="small"
                id={product.id}
                onClick={(e) => removeFromSelected(e)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Card>
      </Container>
    </Page>
  );
};

export default HighlightedArticles;
