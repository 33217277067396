import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import axios from 'axios';
import * as constants from '../../utils/constants';
import Toast from 'src/components/Toast';
import * as helpers from '../../utils/helper_functions';
import { trackPromise } from 'react-promise-tracker';
import { areas } from 'src/utils/areas';

const MakeFreeAccess = ({ isFreeAccess, id, ...rest }) => {
  const [success, setSuccess] = useState();
  const [value, setValue] = useState(isFreeAccess);

  const handleChange = newValue => {
    // const newValue = event.target.checked;
    setValue(newValue);
    submitForm(newValue);
  };

  const submitForm = newValue => {
    setSuccess(undefined);
    trackPromise(
      axios
        .get(
          constants.SERVER_HOST_NAME +
            `/backoffice/book/${id}/set-is-free-access/${newValue}`,
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200 && response.data === 'Successful') {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        })
        .catch(error => {
          setSuccess(false);
        }),
      areas.outside
    );
  };

  return (
    <form autoComplete="off" noValidate {...rest}>
      {success === true ? (
        <Toast status={true} type="success" message="Successful!" />
      ) : null}
      {success === false ? (
        <Toast
          status={false}
          type="error"
          message="Error while saving the data"
        />
      ) : null}
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                cursor: 'pointer'
              }}
              onClick={() => handleChange(!value)}
            >
              <Checkbox checked={value} disabled />
              Is free access
            </div>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

MakeFreeAccess.propTypes = {
  isFreeAccess: PropTypes.bool,
  id: PropTypes.string
};

export default MakeFreeAccess;
