import { Typography } from '@material-ui/core';
import React from 'react';

const Logo = props => {
  return (
    <div style={{ display: 'flex' }}>
      <img
        alt="Logo"
        src="/static/DG_Logo.svg"
        style={{ width: '25px' }}
        {...props}
      />
      <Typography
        style={{ margin: 'auto', marginLeft: '10px', color: 'white' }}
      >
        Artists of the World
      </Typography>
    </div>
  );
};

export default Logo;
