import axios from 'axios';
import * as constants from '../utils/constants';
class Auth {
  constructor() {
    const token = localStorage.getItem('Authorization');
    this.isLoggedIn = token && token?.length > 0;
    // ::dev-only
    // this.isLoggedIn = true;
  }

  async login(values) {
    if (
      values?.email === 'admin@aow.degruyter.com' &&
      values?.password === 'admin@123AOW'
    ) {
      this.isLoggedIn = true;
      localStorage.setItem('Authorization', `Token xyz`);
      return { status: 200, data: { success: true } };
    }
    try {
      // TODO: This endpoint is missing from the backend
      // the code must have been copied from another project
      const url = constants.SERVER_HOST_NAME + '/auth/login';
      console.log(url);
      const response = await axios.post(url, values);
      if (response.status === 200) {
        if (response.data?.success === true) {
          this.isLoggedIn = true;
          localStorage.setItem(
            'Authorization',
            `Token ${response.data.jwtToken}`
          );
        }
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async signUp(values) {
    const response = await axios.post(
      constants.SERVER_HOST_NAME + '/auth/signUp',
      values
    );
    // if (response.status === 200) {
    //     this.isLoggedIn = true;
    //     if (response.data?.success === true) {
    //         localStorage.setItem("Authorization", `Token ${response.data.jwtToken}`);
    //     }
    // }
    return response;
  }

  logout() {
    localStorage.removeItem('Authorization');
    this.isLoggedIn = false;
  }
}

export default new Auth();
