import * as Yup from 'yup';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';
import axios from 'axios';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { trackPromise } from 'react-promise-tracker';
import { areas } from 'src/utils/areas';

const useStyles = makeStyles({
  orStyle: {
    textAlign: 'center'
  }
});

function AddCollectionForm({ open, onAddNew, onCancel }) {
  const classes = useStyles();

  const [Image, setImage] = useState({
    picturePreview: '',
    pictureAsFile: null
  });
  const [ImageURL, setImageURL] = useState('');

  const handleImageChange = e => {
    setImage({
      /* contains the preview, if you want to show the picture to the user
           you can access it with this.state.currentPicture
       */
      picturePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      pictureAsFile: e.target.files[0]
    });
  };

  const uploadImage = setFieldValue => {
    const formData = new FormData();
    formData.append('imageFile', Image.pictureAsFile);

    // for (var key of formData.entries()) {
    //   console.log(key[0] + ', ' + key[1]);
    // }

    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME + '/backoffice/collection/uploadImage',
          formData,
          { 'Content-Type': 'multipart/form-data' }
        )
        .then(res => {
          if (res.data !== null && res.data !== '') {
            setImageURL(res.data);
            setFieldValue('imageURL', res.data);
          }
        })
        .catch(err => console.log(err.message)),
      areas.outside
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add new collection</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            collectionType: 'template',
            name: '',
            description: '',
            imageURL: '',
            artists: [],
            isHomepageBanner: false,
            isHomepageCollection: false,
            isAccessible: false
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .trim()
              .max(255)
              .required('Name is required'),
            collectionType: Yup.string()
              .trim()
              .max(255)
              .required('Collection Type is required'),
            description: Yup.string()
              .trim()
              .max(255)
              .required('Description is required')
          })}
          onSubmit={values => {
            onAddNew(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography>Collection Type:</Typography>
              <select
                name="collectionType"
                value={values.collectionType}
                onChange={handleChange}
                onBlur={handleBlur}
                className="select-input"
              >
                <option value="" label="Select type">
                  Select type
                </option>
                <option value="template" label="Template">
                  template
                </option>
                <option value="free" label="Free">
                  free
                </option>
              </select>
              {errors.collectionType && (
                <div className="input-feedback">{errors.collectionType}</div>
              )}

              <TextField
                error={Boolean(touched.name && errors.name)}
                margin="dense"
                id="name"
                label="Name"
                type="text"
                name="name"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                value={values.name}
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                margin="dense"
                id="description"
                label="Description"
                type="text"
                name="description"
                fullWidth
                required
                onChange={handleChange}
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                value={values.description}
              />
              <Typography sx={{ marginTop: '24px !important' }}>
                Image:
              </Typography>
              <Box
                sx={{
                  margin: '10px !important',
                  marginBottom: '24px !important'
                }}
              >
                <input
                  name="imageInput"
                  type="file"
                  onChange={handleImageChange}
                />
                <Button
                  variant="contained"
                  color="default"
                  onClick={() =>
                    Image.pictureAsFile !== null
                      ? uploadImage(setFieldValue)
                      : null
                  }
                >
                  Upload Image
                </Button>
                {ImageURL !== null && ImageURL.length > 0 ? (
                  <Typography>Image Uploaded!</Typography>
                ) : null}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isHomepageCollection}
                    onChange={() =>
                      setFieldValue(
                        'isHomepageCollection',
                        !values.isHomepageCollection
                      )
                    }
                    name="isHomepageCollection"
                    color="primary"
                  />
                }
                label="Make it Homepage Collection?"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isHomepageBanner}
                    onChange={() =>
                      setFieldValue(
                        'isHomepageBanner',
                        !values.isHomepageBanner
                      )
                    }
                    name="isHomepageBanner"
                    color="primary"
                  />
                }
                label="Make it Homepage Banner?"
              />
              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  onClick={onCancel}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Add
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

AddCollectionForm.propTypes = {
  open: PropTypes.bool,
  onAddNew: PropTypes.func,
  onCancel: PropTypes.func
};

export default AddCollectionForm;
