import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Multiselect from './Multiselect';
import SearchTile from './SearchTile';
import Pagination from '@material-ui/lab/Pagination';
import * as constants from '../../../utils/constants';
import { trackPromise } from 'react-promise-tracker';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Drawer,
  Divider,
  Toolbar as ToolbarCustom,
  Typography,
  IconButton,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  Lock as LockIcon,
  Unlock as UnlockIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from 'react-feather';
import * as helpers from '../../../utils/helper_functions';
import { Spinner } from 'src/components/Spinner/Spinner';
import { areas } from 'src/utils/areas';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';

const drawerWidth = 300;
const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
    padding: '0 15px'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  search: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: 20
  },
  searchShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  cardcontent: {
    position: 'relative',
    minHeight: '80vh'
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [navSearchBox, setNavSearchBox] = useState('');

  const handleSearchBox = event => {
    setNavSearchBox(event.target.value);
  };

  const handleSearchKeydown = event => {
    if (event.key === 'Enter') {
      setNavSearchBox(event.target.value);
      loadData();
    }
  };

  const [data, setData] = useState({});
  const [pageNumber, setpageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [freeFilter, setFreeFilter] = useState(false);

  const history = useNavigate();

  const loadData = async () => {
    sendRequest();
  };

  useEffect(() => {
    loadData();
  }, [freeFilter]);

  const toggleFreeFilter = () => {
    setFreeFilter(!freeFilter);
  };

  const handlePageClick = (event, value) => {
    let selected = value - 1;
    setpageNumber(selected);
    // props.history.push("/search/filterData?page=" + selected);
    sendRequest(selected);
    window.scrollTo(0, 0);
  };

  const [open, setOpen] = useState(false);

  const sendRequest = async newPageNumber => {
    const params = new URLSearchParams();
    params.append('page', newPageNumber ?? pageNumber);

    if (freeFilter) {
      params.append('accessType', 'free');
    }
    console.log(freeFilter);

    let reqBody = {
      search: [{ field: 'ALL_FIELDS', value: navSearchBox }]
    };

    const url = constants.SERVER_HOST_NAME + '/search?' + params.toString();
    console.log(url);

    const res = await trackPromise(
      axios.post(url, reqBody, helpers.getJWTHeader()),
      areas.outside
    );
    setData(res);
    setPageCount(Math.ceil((res?.data?.totalHits ?? 0) / 10));

    // window.history.pushState(
    //   '',
    //   '',
    //   window.location.origin + window.location.pathname + searchParam
    // );
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box>
        <Card>
          <CardContent className={classes.cardcontent}>
            <ToolbarCustom>
              <Typography variant="h4" noWrap className={classes.title}>
                Manage Data | &nbsp;
                {(pageNumber ?? 0) * 10} -{' '}
                {Math.min(
                  (pageNumber ?? 0) * 10 + 10,
                  data?.data?.totalHits ?? 0
                )}{' '}
                of {data?.data?.totalHits ?? 0} results
              </Typography>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search..."
                variant="outlined"
                onChange={handleSearchBox}
                value={navSearchBox}
                onKeyDown={handleSearchKeydown}
                className={clsx(classes.search, {
                  [classes.searchShift]: open
                })}
              />
              <Button color="inherit" edge="end" onClick={loadData}>
                Search
              </Button>
              <IconButton onClick={toggleFreeFilter}>
                {freeFilter ? (
                  <UnlockIcon style={{ color: 'green' }} />
                ) : (
                  <>
                    <UnlockIcon
                      style={{
                        color: 'white',
                        position: 'absolute',
                        left: 17,
                        top: 8
                      }}
                    />
                    <UnlockIcon
                      style={{
                        color: 'green',
                        position: 'absolute',
                        left: 18,
                        top: 9
                      }}
                    />
                    <LockIcon
                      style={{
                        color: 'red'
                      }}
                    />
                  </>
                )}
              </IconButton>
            </ToolbarCustom>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open
              })}
            >
              <div className="searchResults">
                <Spinner area={areas.inside} />
                <OuterSpinner area={areas.outside} />
                {data.data ? (
                  data.data.searchHits.map(item => {
                    return <SearchTile key={item.id} item={item} />;
                  })
                ) : (
                  <div />
                )}
                {data !== undefined && pageNumber !== undefined ? (
                  <Pagination
                    count={pageCount}
                    page={pageNumber + 1}
                    onChange={handlePageClick}
                    color="primary"
                  />
                ) : (
                  <div />
                )}
              </div>
            </main>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
