import React from 'react';
import {
    Container,
    makeStyles
  } from '@material-ui/core';
  import Page from 'src/components/Page';
  import SelectedData from './SelectedData';

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));
  
function Selected() {
    const classes = useStyles();
    return (
        <Page
        className={classes.root}
        title="Collection"
      >
        <Container maxWidth={false}>
          <SelectedData />
        </Container>
      </Page>
    )
}

export default Selected;
