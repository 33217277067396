import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';

import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Home as HomeIcon, Eye, EyeOff, Paperclip } from 'react-feather';

import AddCollectionForm from './AddCollectionForm';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';
import { Plus as PlusIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner/Spinner';
import Toast from '../../components/Toast';
import { areas } from 'src/utils/areas';
import axios from 'axios';
import clsx from 'clsx';
import { trackPromise } from 'react-promise-tracker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  listItem: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'row'
  }
});

function CollectionsList() {
  const [data, setData] = useState();
  const [success, setSuccess] = useState('');
  const [popup, setPopup] = useState(false);

  const onCancel = () => {
    setPopup(false);
  };

  const onAddNewCollection = values => {
    setSuccess(undefined);
    trackPromise(
      axios
        .post(
          constants.SERVER_HOST_NAME + '/backoffice/collection',
          values,
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200) {
            setSuccess('colAdded');
            setPopup(false);
            setData([...data, response.data]);
          } else {
            setSuccess('colAddFailed');
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };

  const deleteCollection = id => {
    trackPromise(
      axios
        .delete(
          `${constants.SERVER_HOST_NAME}/backoffice/collection/${id}`,
          helpers.getJWTHeader()
        )
        .then(res => {
          if (res.status === 200) {
            setSuccess('colDeleted');
            handleMoreMenuClose();
            setData(data.filter(collection => collection.id !== id));
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error);
        }),
      areas.outside
    );
  };

  const loadData = () => {
    trackPromise(
      axios
        .get(constants.SERVER_HOST_NAME + '/collection/all')
        .then(res1 => {
          if (res1.status === 200) {
            setData(res1.data);
          }
          // setTypesMulti(res1.data.packagetype);
          // console.log(typesMulti);
        })
        .catch(err => console.log(err)),
      areas.inside
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleHomepageAction = (id, addCollection) => {
    let url =
      constants.SERVER_HOST_NAME +
      '/backoffice/collection/' +
      (addCollection
        ? 'setAsHomepageCollection'
        : 'deleteFromHomepageCollections');
    trackPromise(
      axios
        .post(url, { id })
        .then(res => {
          if (res?.data === 'Successful') {
            setSuccess(addCollection ? 'homeColSet' : 'homeColRemoved');
            handleMoreMenuClose();
            loadData();
          } else {
            setSuccess(
              addCollection ? 'homeColSetFailed' : 'homeColRemoveFailed'
            );
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error.message);
        }),
      areas.outside
    );
  };

  const setHomepageBanner = id => {
    let url =
      constants.SERVER_HOST_NAME + '/backoffice/collection/setHomepageBanner';
    trackPromise(
      axios
        .post(url, { id })
        .then(res => {
          if (res?.data === 'Successful') {
            setSuccess('homeBannerSet');
            handleMoreMenuClose();
            loadData();
          } else {
            setSuccess('homeBannerSetFailed');
          }
        })
        .catch(error => {
          setSuccess('error');
          console.log(error.message);
        }),
      areas.outside
    );
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState('');

  const handleMoreButtonClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
    console.log(open);
  };
  const handleMoreMenuClose = () => {
    setAnchorEl(null);
    setOpen('');
  };

  return (
    <Card className={clsx(classes.root)}>
      {success === 'success' ? (
        <Toast status={true} type="success" message="Successful!" />
      ) : (
        ''
      )}
      {success === 'error' ? (
        <Toast status={true} type="error" message="Error!" />
      ) : (
        ''
      )}
      {success === 'colAdded' ? (
        <Toast status={true} type="success" message="Collection Added!" />
      ) : (
        ''
      )}
      {success === 'colAddFailed' ? (
        <Toast
          status={true}
          type="warning"
          message="Failed to Add Collection!"
        />
      ) : (
        ''
      )}
      {success === 'colDeleted' ? (
        <Toast status={true} type="success" message="Collection Deleted!" />
      ) : (
        ''
      )}
      {success === 'homeColSet' ? (
        <Toast
          status={true}
          type="success"
          message="Set as Homepage Collection!"
        />
      ) : (
        ''
      )}
      {success === 'homeColSetFailed' ? (
        <Toast
          status={true}
          type="warning"
          message="Failed to Set as Homepage Collection!"
        />
      ) : (
        ''
      )}
      {success === 'homeColRemoved' ? (
        <Toast
          status={true}
          type="success"
          message="Removed from Homepage Collection!"
        />
      ) : (
        ''
      )}
      {success === 'homeColRemoveFailed' ? (
        <Toast
          status={true}
          type="warning"
          message="Failed to Remove from Homepage Collection!"
        />
      ) : (
        ''
      )}
      {success === 'homeBannerSet' ? (
        <Toast status={true} type="success" message="Set as Homepage Banner!" />
      ) : (
        ''
      )}
      {success === 'homeBannerSetFailed' ? (
        <Toast
          status={true}
          type="warning"
          message="Failed to Set as Homepage Banner!"
        />
      ) : (
        ''
      )}

      <CardHeader
        title="Collections"
        action={
          <IconButton aria-label="settings" onClick={e => setPopup(true)}>
            <PlusIcon />
          </IconButton>
        }
      />
      <Divider />
      <Spinner area={areas.inside} />
      <OuterSpinner area={areas.outside} />

      <List>
        {data?.map((collection, i) => (
          <ListItem
            divider={i < data.length - 1}
            key={collection?.id}
            className={classes.listItem}
          >
            <ListItem
              button
              component={RouterLink}
              to={'/app/collections/' + collection.id}
            >
              {collection?.imageURL ? (
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    style={{ width: 56, height: 56, marginRight: 15 }}
                    alt={collection?.name}
                    src={collection?.imageURL}
                  ></Avatar>
                </ListItemAvatar>
              ) : null}

              <ListItemText
                primary={collection?.name}
                secondary={`${collection?.artists?.length ?? 0} Items`}
                className={classes.capitalize}
              />
            </ListItem>

            {collection?.isHomepageBanner && <Paperclip size="20"></Paperclip>}

            {collection?.isHomepageCollection && (
              <HomeIcon size="20"></HomeIcon>
            )}

            {collection?.isAccessible ? (
              <Eye size="20"></Eye>
            ) : (
              <EyeOff size="20"></EyeOff>
            )}

            <IconButton
              aria-label="more"
              id={'more-button_' + collection?.id}
              aria-controls={
                open === collection?.id
                  ? 'more-menu_' + collection?.id
                  : undefined
              }
              aria-expanded={open === collection?.id ? 'true' : undefined}
              aria-haspopup="true"
              edge="end"
              size="small"
              onClick={event => handleMoreButtonClick(event, collection?.id)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={'more-menu_' + collection?.id}
              MenuListProps={{
                'aria-labelledby': 'option-button'
              }}
              anchorEl={anchorEl}
              open={open === collection?.id}
              onClose={event => handleMoreMenuClose()}
              PaperProps={{
                style: {
                  boxShadow: 'none',
                  border: 'solid 0.5px',
                  borderRadius: '0px'
                }
              }}
            >
              {collection?.isHomepageCollection ? (
                <MenuItem
                  onClick={() => handleHomepageAction(collection?.id, false)}
                >
                  Remove from Homepage Collections
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => handleHomepageAction(collection?.id, true)}
                >
                  Add to Homepage Collections
                </MenuItem>
              )}
              {collection?.isHomepageBanner ? null : (
                <MenuItem onClick={() => setHomepageBanner(collection?.id)}>
                  Set as Homepage Banner
                </MenuItem>
              )}

              <MenuItem onClick={() => deleteCollection(collection?.id)}>
                Delete
              </MenuItem>
            </Menu>
          </ListItem>
        ))}
      </List>
      <AddCollectionForm
        open={popup}
        onCancel={onCancel}
        onAddNew={onAddNewCollection}
      />
    </Card>
  );
}

export default CollectionsList;
