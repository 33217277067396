import * as constants from '../../utils/constants';
import * as helpers from '../../utils/helper_functions';

import {
  Card,
  CardHeader,
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';

import { OuterSpinner } from 'src/components/Spinner/OuterSpinner';
import Page from 'src/components/Page';
import { areas } from 'src/utils/areas';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import MakeFreeAccess from './MakeFreeAccess';
import UploadImage from './UploadImage';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Entry = () => {
  const classes = useStyles();
  const [product, setProduct] = useState();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const paramId = urlSearchParams.get('id');

  useEffect(() => {
    loadBook();
  }, [paramId]);

  const loadBook = () => {
    trackPromise(
      axios
        .get(
          constants.SERVER_HOST_NAME + '/book/' + paramId,
          helpers.getJWTHeader()
        )
        .then(response => {
          if (response.status === 200) {
            setProduct(response.data);
          }
        }),
      areas.outside
    );
  };

  return (
    <Page className={classes.root} title={product?.title}>
      <OuterSpinner area={areas.outside} />
      <Container maxWidth={false}>
        <Box marginY={3}>
          <Card>
            <CardHeader title={product?.title} />
          </Card>
        </Box>
        {product ? (
          <>
            <Box marginY={1}>
              <MakeFreeAccess
                id={product.id}
                isFreeAccess={product.isFreeAccess}
              />
            </Box>
            <Box marginY={1}>
              <UploadImage
                imageURL={product.imageURL}
                id={product.id}
                entryType={product.entryType}
              />
            </Box>
          </>
        ) : (
          <></>
        )}
      </Container>
    </Page>
  );
};

export default Entry;
