import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import "./OuterSpinner.css";

export const OuterSpinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({area: props.area, delay: 0});

  return (
    promiseInProgress && (
      <div className="outer-spinner">
        <Loader type="Rings" color="#2BAD60" height="100" width="100" />
      </div>
    )
  );
};

